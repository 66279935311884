import { StrictMode } from 'react';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  SplitClient,
  SplitFactory,
  SplitTreatments,
} from '@splitsoftware/splitio-react';
import {
  theme,
  splitRootFactory,
  globalstate$,
  FEATURE_FLAGS,
} from '@smf/ui-util-app';
import ConfigContainer from './containers/ConfigContainer';
import { ToastProvider } from './context/toastContext';
import MESSAGE_STRINGS from './constants/en-us';

export default function Root() {
  const queryClient = new QueryClient();

  const renderApp = (treatments) => {
    const { treatment } = treatments || {};
    if (treatment === 'off') {
      navigateToUrl('/v2');
      return <>Module Unavailable</>;
    }
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <ConfigContainer />
          </ToastProvider>
        </ThemeProvider>
      </QueryClientProvider>
    );
  };
  return (
    <StrictMode>
      <SplitFactory factory={splitRootFactory}>
        <SplitClient
          splitKey={globalstate$._value?.useData?.email?.toLowerCase()}
        >
          <SplitTreatments names={[FEATURE_FLAGS.YP]}>
            {({ treatments, isReady }) => {
              return isReady ? (
                renderApp(treatments[FEATURE_FLAGS.YP])
              ) : (
                <>{MESSAGE_STRINGS.LOADING_INDICATOR}</>
              );
            }}
          </SplitTreatments>
        </SplitClient>
      </SplitFactory>
    </StrictMode>
  );
}
